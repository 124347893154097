import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`20:00 to establish max of:`}</p>
    <p>{`3-Push Jerks + 1-Split Jerk`}</p>
    <p>{`then,`}</p>
    <p>{`For time:`}</p>
    <p>{`500M Ski Erg`}</p>
    <p>{`100 Double Unders`}</p>
    <p>{`75 Situps`}</p>
    <p>{`50 S2OH (95/65)`}</p>
    <p><em parentName="p">{`*`}{`compare to 8/22/19`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      